<template>
    <div>
        <b-row>
            <b-col>
                <ek-input-select
                    name="filter"
                    :options="lessonTypeList"
                    v-model="filterValue.filterValueType"
                    placeholder="نوع المستند"
                    clearable
                ></ek-input-select>
            </b-col>
            <b-col>
                <ek-input-select
                    name="filter"
                    :options="subjectOptions"
                    v-model="filterValue.filterValueSubject"
                    placeholder="المادة"
                    clearable
                ></ek-input-select>
            </b-col>
            <b-col>
                <ek-input-select
                    clearable
                    name="filter"
                    placeholder="الفصل"
                    :options="semesterList"
                    v-model="filterValue.filterValueSemester"
                    valueLabel="name"
                ></ek-input-select>
            </b-col>
        </b-row>
        <ek-table
            :columns="lessonsTableCols"
            :items="resultLessonList"
            @details="goToDetails"
            @delete-selected="deleteRangeLessons"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ new Date(value).toLocaleDateString() }}
            </template>
            <template slot="items.lessonType" slot-scope="{ value }">
                {{
                    value
                        ? lessonTypeList.find((ele) => ele.id == value).name
                        : ""
                }}
            </template>
            <template slot="items.semester" slot-scope="{ value }">
                {{
                   value
                        ? value : ""
                }}
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
export default {
    computed: {
        ...mapState({
            // lessons: (state) => state.chapter.lessons,

            lessons: ({ lessons }) => lessons.lessons,
            lessonsTableCols: ({ lessons }) => lessons.lessonsTableCols,
            lessonFilterDto: ({ lessons }) => lessons.lessonFilterDto,
            lessonOptions: ({ lessons }) => lessons.lessonOptions,
            lessonTypeList: ({ lessons }) => lessons.lessonTypeList,
            // semesterList: ({ lessons }) => lessons.semesterList,
            semesterList: ({ settings }) => settings.semesterList.semesters,

            filterValue: ({ lessons }) => lessons.filterValue,
            subjectOptions: ({ subjects }) => subjects.subjectOptions,
            semesterOptions: ({ lessons }) => lessons.semesterOptions,
        }),
        ...mapGetters(["resultLessonList"]),
    },
    methods: {
        ...mapActions([
            "getLessons",
            "getUnitsOptions",
            "getSubjectOptions",
            "deleteRangeLessons",
            "getSettingSemester",
        ]),
        goToDetails({ row }) {
            this.$router.push({
                path: `/admin/lessons/${row.id}`,
                query: { name: row.name, lessonType: row.lessonType },
            });
        },
    },
    created() {
        this.getLessons();
        this.getSettingSemester();
    },
};
</script>

<style></style>
